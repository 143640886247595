$('.main-nav__list-link').click(function (event) {
	$('#offcanvasRight').offcanvas('hide');
	$('.modal').modal('hide');

	// On-page links
	if (location.pathname.replace(/^\//, '') == this.pathname.replace(/^\//, '') && location.hostname == this.hostname) {
		// Figure out element to scroll to
		var target = $(this.hash);
		target = target.length ? target : $('[name=' + this.hash.slice(1) + ']');
		// Does a scroll target exist?
		if (target.length) {
			// Only prevent default if animation is actually gonna happen
			event.preventDefault();
			$heightHeader = $('.main-nav').height();
			$('html, body').animate({
				scrollTop: target.offset().top - $heightHeader
			}, 500, function () {
				// Callback after animation
				// Must change focus!
				var $target = $(target);
				$target.focus();
				if ($target.is(":focus")) {
					// Checking if the target was focused
					return false;
				} else {
					$target.attr('tabindex', '-1');
					// Adding tabindex for elements not focusable
					$target.focus();
					// Set focus again
				}
				;
			});
		}
	}
});